.modal-wrapper {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modal-window {
  z-index: 2;
}

.open {
  visibility: visible;
}

.close {
  visibility: hidden;
}
