.half {
  width: 50%;
}

.closeButton {
  cursor: pointer;
}

@media (max-width: 450px) {
  .edit {
    padding-bottom: 100px;
  }
}
